import {
    Add,
    Remove,
    ArrowRight,
    ArrowLeftOutlined,
    ArrowRightOutlined,
} from "@material-ui/icons";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import { mobile, tablet } from "../responsive";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { publicRequest } from "../requestMethods";
import { addProduct, addQuantity } from "../redux/cartRedux";
import { useDispatch, useSelector } from "react-redux";
import ProductVideo from "../components/ProductVideo";

const NumberFormatter = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const Container = styled.div`
    font-size: 1.2rem;
    line-height: 2.5rem;
    ${mobile({
        fontSize: "1rem",
    })}
    ${tablet({
        fontSize: "1rem",
    })}
`;

const ProductDetails = styled.div`
    background-image: url(${(props) => props.coverbg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: black;
    height: 100vh;
    display: flex;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 3rem;

    ${mobile({
        height: "60vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
    })}
    ${tablet({
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
    })}
`;

const ProductImage = styled.img`
    height: 100vh;
    ${mobile({
        display: "none",
    })}
`;

const MobileProductImage = styled.img`
    display: none;
    ${mobile({
        display: "block",
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
    })}
`;

const Wrapper = styled.div`
    padding: 10px 50px;
    display: flex;
    ${mobile({ padding: "10px", flexDirection: "column" })}
    ${tablet({ padding: "10px", flexDirection: "column" })}
`;

const CarouselContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: 70vh;
    display: flex;
    flex-direction: column;
    padding-right: 15px;
    flex: 0.25;
    ${mobile({ flexDirection: "row", overflow: "auto", order: "2" })}
    ${tablet({ flexDirection: "row", overflow: "auto", order: "2" })}
`;

const Carousel = styled.img`
    margin: 10px;
    cursor: pointer;
    width: 100%;
    height: 15vh;
    width: 15vh;
    object-fit: contain;
    background: white;
    ${mobile({ height: "12vh", margin: "2rem 0" })}
    ${tablet({ height: "12vh", margin: "2rem 0" })}
`;

const Gallery = styled.img`
    cursor: pointer;
    width: 100%;
    height: 60vh;
    object-fit: cover;
    background: white;
    ${mobile({ height: "15vh", margin: "0px 0px" })}
    ${tablet({ height: "100%", margin: "0px 0px" })}
`;

const ImgContainer = styled.div`
    flex: 1;
    ${mobile({ order: "1" })}
    ${tablet({ order: "1" })}
`;

const Image = styled.img`
    width: 100%;
    height: 60vh;
    object-fit: contain;
    cursor: pointer;
    ${mobile({ height: "30vh", margin: "0" })}
    ${tablet({ height: "50vh", margin: "0" })}
`;

const FullImage = styled.img`
    width: 75vh;
    height: 100vh;
    object-fit: contain;
    cursor: pointer;
    background: white;
    ${mobile({ height: "30vh", margin: "0" })}
    ${tablet({ height: "30vh", margin: "0" })}
`;

const GalleryFullImage = styled.img`
    width: 100vh;
    height: 100vh;
    object-fit: contain;
    cursor: pointer;
    ${mobile({ height: "30vh", margin: "0" })}
    ${tablet({ height: "30vh", margin: "0" })}
`;

const InfoContainer = styled.div`
    flex: 1;
    ${mobile({ padding: "0rem 10px", order: "3" })}
    ${tablet({ padding: "0rem 10px", order: "3" })}
`;

const Title = styled.h1`
    font-family: FuturaPT-Bold;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 800;
    padding-bottom: 10px;
    overflow-wrap: anywhere;
    ${mobile({ fontSize: "1.5rem", margin: "0" })}
    ${tablet({ fontSize: "1.5rem", margin: "0" })}
`;

const Subtitle = styled.p`
    font-family: FuturaPT-Book;
    ${mobile({ padding: "0", lineHeight: "2.5rem", fontSize: "1rem" })}
    ${tablet({ padding: "0", lineHeight: "2.5rem", fontSize: "1rem" })}
`;

const Desc = styled.p`
    font-family: FuturaPT-Book;
    font-weight: 400;
    ${mobile({ padding: "0", lineHeight: "2.5rem", fontSize: "1rem" })}
    ${tablet({ padding: "0", lineHeight: "2.5rem", fontSize: "1rem" })}
`;

const WatchContainer = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    ${mobile({ marginBottom: "0" })}
    ${tablet({ marginBottom: "0" })}
`;

const Price = styled.div`
    font-family: FuturaPT-Book;
    font-size: 20px;
    ${mobile({ fontSize: "1rem" })}
    ${tablet({ fontSize: "1rem" })}
`;

const AddContainer = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${mobile({ width: "100%", margin: "0" })}
    ${tablet({ width: "100%", margin: "0" })}
`;

const AmountContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: 700;
`;

const Amount = styled.span`
    width: 30px;
    height: 30px;
    border-radius: 10px;
    border: 1px solid teal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;
`;

const Button = styled.button`
    width: 80%;
    margin: 25px 0;
    padding: 15px;
    border: 2px solid #1f2f4d;
    background-color: black;
    color: white;
    cursor: pointer;
    font-weight: 500;

    &:hover {
        background-color: #1f2f4d;
        border: 2px solid transparent;
    }
    ${mobile({ width: "100%", margin: "1rem 0" })}
    ${tablet({ width: "100%", margin: "1rem 0" })}
`;

const ColorContainer = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    border-top: 1px solid #eae8e4;
    border-bottom: 1px solid #eae8e4;
    margin-bottom: 1rem;
    width: 80%;
    ${mobile({ width: "100%", marginBottom: "0.5rem" })}
    ${tablet({ width: "100%", marginBottom: "0.5rem" })}
`;

const RightColor = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const Color = styled.p`
    line-height: 0.9rem;
    font-size: 1rem;
    font-weight: 400;
    ${mobile({ padding: "0", lineHeight: "1rem", fontSize: "1rem" })}
    ${tablet({ padding: "0", lineHeight: "1rem", fontSize: "1rem" })}
`;

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.75;
    position: fixed;
    z-index: 10;
`;

const ImageModalContainer = styled.div`
    height: 100%;
    width: 100%;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ImageOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: white;
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100%;
    width: 33.33%;
    display: flex;
    z-index: 102;
    ${mobile({ width: "100%", height: "100%" })}
    ${tablet({ width: "100%", height: "100%" })}
`;

const EmptyModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 33.33%;
    display: flex;
    z-index: 102;
    ${mobile({ width: "100%", height: "100%", display: "none" })}
    ${tablet({ width: "100%", height: "100%", display: "none" })}
`;

const ModalItemContainer = styled.div`
    background-color: white;
    color: black;
    height: 100%;
    width: 100%;
    display: grid;
    ${mobile({ width: "100%", height: "100%" })}
    ${tablet({ width: "100%", height: "100%" })}
`;

const ColorPickerContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: 80vh;
    display: grid;
    grid-template-columns: auto auto;
    z-index: 101;
    ${mobile({ display: "flex", flexDirection: "column" })}
    ${tablet({ display: "flex", flexDirection: "column" })}
`;

const ColorPicker = styled.img`
    margin: 20px 10px;
    cursor: pointer;
    width: 90%;
    height: 30vh;
    object-fit: contain;
    background: white;
    ${mobile({ height: "20vh" })}
    ${tablet({ height: "20vh" })}
`;

const ColorTitle = styled.div`
    display: flex;
    justify-content: center;
    font-family: "FuturaPT-Book";
    font-size: 1.8rem;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid gray;
    ${mobile({ fontSize: "1.2rem" })}
    ${tablet({ fontSize: "1.2rem" })}
`;

const Close = styled.div`
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 2rem;
`;

const GalleryWrapper = styled.div`
    justify-content: center;
    ${mobile({ padding: "0px", flexDirection: "column" })}
    ${tablet({ padding: "0px", flexDirection: "column" })}
`;

const GalleryContainer = styled.div`
    width: 100vw;
    grid-template-columns: 25% 25% 25% 25%;
    display: grid;
    ${mobile({ flexDirection: "row", overflow: "auto", order: "2" })}
    ${tablet({ gridTemplateColumns: "50% 50%" })}
`;

const Arrow = styled.div`
    width: 50px;
    height: 50px;
    background-color: #fff7f7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${(props) => props.direction === "left" && "10px"};
    right: ${(props) => props.direction === "right" && "10px"};
    margin: auto;
    cursor: pointer;
    opacity: 0.5;
    z-index: 9999;
`;

const GalleryModalContainer = styled.div`
    height: 100%;
    width: 100%;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100;
`;

const Product = () => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const [imageModal, setImageModal] = useState(false);
    const [galleryModal, setGalleryModal] = useState(false);
    const [product, setProduct] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [colorpicker, setColorPicker] = useState(false);
    const [activeColor, setActiveColor] = useState({});
    const dispatch = useDispatch();

    const admin = useSelector((state) => state.cart.products);

    const colorNames =  [
        { name: "BLK", color: "Black" },
        { name: "RED", color: "Red" },
        { name: "TGR", color: "Tiger" },
        { name: "FSH", color: "Fish" },
        { name: "PND", color: "Panda" },
        { name: "H1", color: "Horse 1" },
        { name: "H2", color: "Horse 2" },
        { name: "BEE", color: "Bee" },
        { name: "BLUE", color: "Blue" },
        { name: "DBLU", color: "Dark Blue" },
        { name: "LBLU", color: "Light Blue" },
        { name: "WHE", color: "White" },
        { name: "GRN", color: "Green" },
        { name: "YLW", color: "Yellow" },
        { name: "SE", color: "SE Limited" },
        { name: "ORG", color: "Orange" },
        { name: "GOLD", color: "Gold" },
        { name: "SVR", color: "Silver" },
        { name: "BRED", color: "Black/Red" },
        { name: "BBLU", color: "Black/Blue" },
        { name: "BYLW", color: "Black/Yellow" },
        { name: "BRW", color: "Brown" },
        { name: "PRP", color: "Purple" },
        { name: "BBRW", color: "Black/Brown" },
        { name: "BORG", color: "Black/Orange" },
        { name: "BWHE", color: "Black/White" },
        { name: "BGRN", color: "Black/Green" },
        { name: "SBRW", color: "Silver/Brown" },
        { name: "RBRW", color: "Red/Brown" },
        { name: "WBLU", color: "White/Blue" },
        { name: "WYLW", color: "White/Yellow" },
        { name: "WBRW", color: "White/Brown" },
        { name: "WPRP", color: "White/Purple" },
        { name: "WGRN", color: "White/Green" },
        { name: "WGLD", color: "White/Gold" },
        { name: "PNK", color: "Pink" },
        { name: "SBLU", color: "Silver/Blue" },
        { name: "SBLK", color: "Silver/Black" },
        { name: "SBRW", color: "Silver/Brown" },
        { name: "BLBR", color: "Blue/Brown" },
        { name: "GBLK", color: "Gold/Black" },
        { name: "GSVR", color: "Gold/Silver" },
        { name: "GBRW", color: "Gold/Brown" },
        { name: "GGRN", color: "Gold/Green" },
        { name: "SGRN", color: "Silver/Green" },
        { name: "GWHE", color: "Gold/White" },
        { name: "GBLU", color: "Gold/Blue" },
        { name: "RBLK", color: "Red/Black" },
        { name: "OBLK", color: "Orange/Black" },
        { name: "BGRN", color: "Black/Green" },
        { name: "RGLD", color: "Rose/Gold" },
        { name: "RBOW", color: "Rainbow" },
        { name: "STRAP", color: "STRAP", hexcode: "#00FFFFFF" },
        { name: "WINDER", color: "Watch Winder", hexcode: "#ffffff" },
    ];

    const colorIndex = activeColor.color
        ? colorNames.map((e) => e.name).indexOf(activeColor.color.name)
        : undefined;

    useEffect(() => {
        window.scrollTo(0, 0);
        const getProduct = async () => {
            try {
                const res = await publicRequest.get("/products/find/" + id);
                setProduct(res.data);
                setActiveColor({ color: res.data.color[0], i: 0 });
            } catch {}
        };
        getProduct();
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (colorpicker === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [colorpicker]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (imageModal === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [imageModal]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (galleryModal === false) {
            document.body.style.overflow = "inherit";
        } else {
            document.body.style.overflow = "hidden";
        }
    }, [galleryModal]);

    const handleQuantity = (type) => {
        if (type === "dec") {
            quantity > 1 && setQuantity(quantity - 1);
        } else {
            setQuantity(quantity + 1);
        }
    };

    const handleClick = () => {
        try {

            const discountProduct = {
                ...product,
                myprice: (() => {
                    switch (product.model) {
                        case "1985":
                        case "1982":
                        case "1991L-1":
                            return 2680;
                        case "2000":
                        case "2388":
                        case "2388G":
                            return product.myprice;
                        default:
                            return product.myprice * 0.88;
                    }
                })(),
            };
            
            if (
                admin.find(
                    (item) =>
                        `${item.model}-${item.color.name}` ===
                        `${product.model}-${activeColor.name}`
                ) === undefined
            ) {
                dispatch(addProduct({ ...discountProduct, activeColor, quantity }));
            } else {
                dispatch(addQuantity({ ...discountProduct, activeColor, quantity }));
            }
            alert("Product added to cart");
            window.scrollTo(0, 0);
        } catch {}
    };

    const handleGallerySwipe = (direction) => {
        const totalGallery = product.gallery.length;
        if (direction === "right") {
            const newIndex =
                galleryModal.i + 1 < totalGallery ? galleryModal.i + 1 : 0;
            setGalleryModal({
                gallery: product.gallery[newIndex],
                i: newIndex,
            });
        } else {
            const newIndex =
                galleryModal.i - 1 < 0 ? totalGallery - 1 : galleryModal.i - 1;
            setGalleryModal({
                gallery: product.gallery[newIndex],
                i: newIndex,
            });
        }
    };

    const handleImageSwipe = (direction) => {
        const totalColor = product.color.length;
        if (direction === "right") {
            const newIndex =
                activeColor.i + 1 < totalColor ? activeColor.i + 1 : 0;
            setActiveColor({
                color: product.color[newIndex],
                i: newIndex,
            });
        } else {
            const newIndex =
                activeColor.i - 1 < 0 ? totalColor - 1 : activeColor.i - 1;
            setActiveColor({
                color: product.color[newIndex],
                i: newIndex,
            });
        }
    };
    return (
        <Container>
            <Navbar />
            <Announcement />
            <>
                {" "}
                <Wrapper>
                    <CarouselContainer>
                        {product.color
                            ? product.color.map((color, i) => (
                                  <Carousel
                                      key={i}
                                      src={color.img}
                                      onClick={() =>
                                          setActiveColor({ color: color, i })
                                      }
                                      style={
                                          activeColor.color
                                              ? color.img ===
                                                activeColor.color.img
                                                  ? {
                                                        border: "1px solid black",
                                                    }
                                                  : {
                                                        border: "1px solid transparent",
                                                    }
                                              : { border: "1px solid black" }
                                      }
                                  />
                              ))
                            : ""}
                    </CarouselContainer>
                    <ImgContainer>
                        <Image
                            onClick={() => {
                                window.innerWidth > 480
                                    ? setImageModal(true)
                                    : setImageModal(false);
                            }}
                            src={
                                activeColor.color
                                    ? activeColor.color.img
                                        ? activeColor.color.img
                                        : ""
                                    : ""
                            }
                        />
                    </ImgContainer>
                    <InfoContainer>
                        <WatchContainer>
                            <Title>{product.title}</Title>
                            <Subtitle>
                                {" "}
                                {activeColor.color
                                    ? activeColor.color.name
                                        ? `Model : ${product.model}- ${colorNames[colorIndex].color}`
                                        : ""
                                    : ""}
                            </Subtitle>
                            <Desc>
                                {" "}
                                {product.movementModel
                                    ? `Movement Model : ${product.movementModel}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.powerReserve
                                    ? `Power Reserve : ${product.powerReserve}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.vibrationFrequency
                                    ? `Vibration Frequency : ${product.vibrationFrequency}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.drive
                                    ? `Drive : ${product.drive}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.windingMethod
                                    ? `Winding Method : ${product.windingMethod}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.caseDiameter
                                    ? `Case Diameter : ${product.caseDiameter}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.caseMaterial
                                    ? `Case Material : ${product.caseMaterial}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.waterResistance
                                    ? `Water Resistance : ${product.waterResistance}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.mirror
                                    ? `Mirror : ${product.mirror}`
                                    : ""}
                            </Desc>
                            <Desc>
                                {" "}
                                {product.strapMaterial
                                    ? `Strap Material : ${product.strapMaterial}`
                                    : ""}
                            </Desc>
                        </WatchContainer>
                        <ColorContainer onClick={() => setColorPicker(true)}>
                            <Color>Color</Color>
                            <RightColor>
                                <Desc>
                                    {" "}
                                    {activeColor.color
                                        ? activeColor.color.name
                                            ? `${colorNames[colorIndex].color}`
                                            : ""
                                        : ""}
                                </Desc>
                                <ArrowRight />
                            </RightColor>
                        </ColorContainer>
                        <AddContainer>
                        {product.label === "soldout" ? (
    ``
) : (
    <>
        {product.myprice > 1 ? (
            <Price>
                {(() => {
                    switch (product.model) {
                        case "1985": 
                        return `RM ${NumberFormatter.format((2680))}`;
                        case "1982": 
                        return `RM ${NumberFormatter.format((2680))}`;
                        case "1991L-1": 
                        return `RM ${NumberFormatter.format((2680))}`;
                        case "2000": 
                        return `RM ${NumberFormatter.format((product.myprice))}`;
                        case "2388": 
                        return `RM ${NumberFormatter.format((product.myprice))}`;
                        case "2388G": 
                        return `RM ${NumberFormatter.format((product.myprice))}`;
                        default:
                            return `RM ${NumberFormatter.format((product.myprice * 0.88))}`;
                    }
                })()}
            </Price>
        ) : (
            <div>
                <Price>
                    {(() => {
                        switch (product.model) {
                            case "2323":
                            case "1909L":
                            case "1909L-1":
                            case "2203":
                            case "1901-1":
                                return `RM ${(product.myprice).toLocaleString("en-US")}.00`; // Applying 580 discount
                            case "2290":
                            case "2291":
                                return `RM ${(product.myprice).toLocaleString("en-US")}.00`; // Applying 880 discount
                            case "2000":
                                return `RM ${(product.myprice).toLocaleString("en-US")}.00`; // Applying 900 discount
                            case "1986":
                                return `RM ${(product.myprice).toLocaleString("en-US")}.00`; // Applying 380 discount
                            case "1978":
                                return `RM ${(product.myprice).toLocaleString("en-US")}.00`; // Applying 480 discount
                            default:
                                return `RM ${(product.myprice)}`; 
                        }
                    })()}
                </Price>
            </div>
        )}
    </>
)}

                            <AmountContainer>
                                <Remove onClick={() => handleQuantity("dec")} />
                                <Amount>{quantity}</Amount>
                                <Add onClick={() => handleQuantity("inc")} />
                            </AmountContainer>
                        </AddContainer>
                       {
                        product.label === "soldout" ?  <Button>LIMITED</Button> :  <Button onClick={handleClick}>ADD TO CART</Button>
                       }
                    </InfoContainer>
                </Wrapper>
                <ProductDetails coverbg={product.coverbg}>
                    <ProductImage src={product.cover} />
                    <MobileProductImage src={product.mobilecover} />
                </ProductDetails>
                <ProductVideo video={product.video} />
                <GalleryWrapper>
                    {" "}
                    <GalleryContainer>
                        {product.gallery
                            ? product.gallery.map((gallery, i) => (
                                  <Gallery
                                      key={i}
                                      src={gallery.img}
                                      onClick={() => {
                                          setGalleryModal({ gallery, i });
                                      }}
                                  />
                              ))
                            : ""}
                    </GalleryContainer>
                </GalleryWrapper>
            </>
            {colorpicker && (
                <OverlayContainer>
                    <ModalContainer></ModalContainer>
                    <Overlay>
                        <EmptyModalDetails
                            onClick={() => setColorPicker(false)}
                        ></EmptyModalDetails>
                        <EmptyModalDetails
                            onClick={() => setColorPicker(false)}
                        ></EmptyModalDetails>
                        <ModalDetails>
                            <ModalItemContainer>
                                <ColorTitle>COLOURS</ColorTitle>
                                <ColorPickerContainer>
                                    {product.color
                                        ? product.color.map((color, i) => (
                                              <ColorPicker
                                                  key={i}
                                                  src={color.img}
                                                  onClick={() => {
                                                      setActiveColor({
                                                          color: color,
                                                          i,
                                                      });
                                                      setColorPicker(false);
                                                  }}
                                                  style={
                                                      color.img ===
                                                      activeColor.color.img
                                                          ? {
                                                                border: "1px solid black",
                                                            }
                                                          : {
                                                                border: "1px solid transparent",
                                                            }
                                                  }
                                              />
                                          ))
                                        : ""}
                                </ColorPickerContainer>
                            </ModalItemContainer>
                        </ModalDetails>
                    </Overlay>
                </OverlayContainer>
            )}
            {imageModal && (
                <>
                    <Arrow
                        direction="left"
                        onClick={() => handleImageSwipe("left")}
                    >
                        <ArrowLeftOutlined />
                    </Arrow>
                    <ImageModalContainer
                        onClick={() => setImageModal(false)}
                    ></ImageModalContainer>
                    <ImageOverlay>
                        <ModalDetails>
                            <Close onClick={() => setImageModal(false)}>
                                X
                            </Close>
                            <FullImage
                                onClick={() => setImageModal(true)}
                                src={
                                    activeColor.color.img
                                        ? activeColor.color.img
                                        : ""
                                }
                            />
                        </ModalDetails>
                    </ImageOverlay>
                    <Arrow
                        direction="right"
                        onClick={() => handleImageSwipe("right")}
                    >
                        <ArrowRightOutlined />
                    </Arrow>
                </>
            )}

            {galleryModal && (
                <>
                    <Arrow
                        direction="left"
                        onClick={() => handleGallerySwipe("left")}
                    >
                        <ArrowLeftOutlined />
                    </Arrow>
                    <GalleryModalContainer
                        onClick={() => setGalleryModal(false)}
                    ></GalleryModalContainer>
                    <ImageOverlay>
                        <ModalDetails>
                            <Close onClick={() => setGalleryModal(false)}>
                                X
                            </Close>
                            <GalleryFullImage
                                onClick={() => setGalleryModal(galleryModal)}
                                src={
                                    galleryModal
                                        ? product.gallery[galleryModal.i].img
                                        : ""
                                }
                            />
                        </ModalDetails>
                    </ImageOverlay>
                    <Arrow
                        direction="right"
                        onClick={() => handleGallerySwipe("right")}
                    >
                        <ArrowRightOutlined />
                    </Arrow>
                </>
            )}
            <Newsletter />
            <Footer />
        </Container>
    );
};

export default Product;
